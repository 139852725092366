import React from 'react';
import { Outlet } from 'react-router-dom';
import DownloadBanner from "../DownloadBanner";

const BaseLayout = () => {
  return (
    <div className={'h-full'}>
      <main><Outlet/></main>
      <DownloadBanner />
    </div>
  );
};

export default BaseLayout;
