import { useCallback, useEffect, useRef, useState } from 'react';
import Map, {Marker, NavigationControl, ScaleControl, GeolocateControl} from 'react-map-gl';
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import { getStores } from "../services/stores";
import StoreDetail from "../components/StoreDetail";
import type { Store } from "../models/stores";
import { BOTTOM_BANNER_HEIGHT } from "../components/DownloadBanner";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken = 'pk.eyJ1IjoibmVhdGNhciIsImEiOiJja3Rpenl6MTAxN2hoMzJubHlmZGVncHQwIn0.Rw2zGwd6EXBJY8_k4Sp1gA';

const NeatcarMap = () => {
  const [mapHeight, setMapHeight] = useState(window.innerHeight - BOTTOM_BANNER_HEIGHT);
  const [mapWidth, setMapWidth] = useState(window.innerWidth);
  const [stores, setStores] = useState([]);
  const [userPosition, setUserPosition] = useState();
  const [selectedStore, setSelectedStore] = useState();
  const [detailOpen, setDetailOpen] = useState(false);
  const mapRef = useRef(null);

  const handleResize = () => {
    setMapHeight(window.innerHeight - BOTTOM_BANNER_HEIGHT);
    setMapWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const geolocateControlRef = useCallback((ref) => {
    if (ref && mapRef) {
      // Activate as soon as the control is loaded
      ref.trigger();
    }
  }, [mapRef]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(pos => {
      setUserPosition(pos.coords);
    });
    getStores().subscribe(stores => {
      setStores(stores);
    });
  }, []);

  const select = (store: Store) => {
    setSelectedStore(store);
    setDetailOpen(true);
  }

  return (
    <div className={"h-full w-full bg-neatcar"}>
      <StoreDetail store={selectedStore} open={detailOpen} onClose={() => setDetailOpen(false)} />
      <Map
        ref={mapRef}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        initialViewState={{
          longitude: -73.567256, // Montreal
          latitude: 45.5016889,
          zoom: 11
        }}
        style={{ height: `${mapHeight}px`, width:  `${mapWidth}px` }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        <NavigationControl position={'top-left'} />
        <ScaleControl position={'bottom-left'} />
        <GeolocateControl ref={geolocateControlRef} position={'top-left'} />
        {/*{userPosition && (*/}
        {/*  <Marker key={'me'} latitude={userPosition.latitude} longitude={userPosition.longitude}>*/}
        {/*    <LocationMarkerIcon className={"h-8 w-8 text-neatcar"}/>*/}
        {/*  </Marker>*/}
        {/*)}*/}
        {stores.map((s: Store) => {
          return (<Marker key={s.id} latitude={s.lat} longitude={s.lng} onClick={() => select(s)}>
            <img width={32} src={s.brand.smallLogo} alt={s.brand.name}/>
          </Marker>);
        })}
      </Map>
    </div>
  );
}

export default NeatcarMap;
