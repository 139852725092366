import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import NeatcarButton from "./NeatcarButton";
import qr from '../assets/images/qr.png';
import downloadPlayStoreFr from '../assets/images/google-play-neatcar.png';
import downloadAppStoreFr from '../assets/images/app-store-neatcar.png';
import downloadPlayStoreEn from './../assets/images/google-play-neatcar-en.png';
import downloadAppStoreEn from './../assets/images/app-store-neatcar-en.png';
import sampleApp from './../assets/images/sample-app.png';

const DownloadAppModal = ({open, onClose, buy}) => {
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log(data)
    setLoading(true);
    // DO SOMETHING
    onClose();
  }

  const renderContent = () => {
    if (buy) {
      return (
        <div className="flex flex-row gap-x-8 mt-8 items-stretch items-center">
          <div className="flex items-end">
            <img src={sampleApp} alt={'Neatcar app'}/>
          </div>
          <div className="flex flex-col gap-y-6">
            <div className="flex flex-col gap-y-2">
              <h2 className="font-bold">{t('download')}</h2>
              <p className="text-md text-neatcarGray">{t('downloadDesc')}</p>
              <div className="flex flex-row gap-x-4">
                <a href={t('playStoreLink')} target={'_blank'} rel="noreferrer">
                  <img src={i18n.language === 'fr' ? downloadPlayStoreFr : downloadPlayStoreEn} width={'80'} alt={'Download on Google Play Store'} />
                </a>
                <a href={t('appStoreLink')} target={'_blank'} rel="noreferrer">
                  <img src={i18n.language === 'fr' ? downloadAppStoreFr : downloadAppStoreEn} width={'80'} alt={'Download on Apple App Store'} />
                </a>
              </div>
            </div>
            <div>
              <h2 className="font-bold">{t('choose')}</h2>
              <p className="text-md text-neatcarGray">{t('chooseDesc')}</p>
            </div>
            <div>
              <h2 className="font-bold">{t('pay')}</h2>
              <p className="text-md text-neatcarGray">{t('payDesc')}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row gap-x-8 mt-8">
          <a href={t('playStoreLink')} target={'_blank'} rel="noreferrer">
            <img src={i18n.language === 'fr' ? downloadPlayStoreFr : downloadPlayStoreEn} width={'120'} alt={'Download on Google Play Store'} />
          </a>
          <a href={t('appStoreLink')} target={'_blank'} rel="noreferrer">
            <img src={i18n.language === 'fr' ? downloadAppStoreFr : downloadAppStoreEn} width={'120'} alt={'Download on Apple App Store'} />
          </a>
        </div>
      );
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <form className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0" onSubmit={handleSubmit(onSubmit)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => onClose()}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex flex-col justify-center items-center">
                  {!buy && (<div>
                    <img src={qr} alt={'qr code'}/>
                  </div>)}
                  <h1 className="text-3xl text-neatcar font-bold">{t('scanTheCode')}</h1>
                  <h3 className="text-md text-neatcarGray text-center">{t('scanTheCodeDesc')}</h3>
                  {renderContent()}
                  <hr className="self-stretch my-8" />
                  <span className="self-start text-sm text-neatcarGray mb-2">{t('sendToPhoneDesc')}</span>
                  <div className="flex self-stretch flex-row gap-x-8">
                    <InputMask
                      {...register("phoneNumber", { required: { value: true, message: t('phoneRequired') }, pattern: /^\(\+1\) \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/ })}
                      mask="(+1) (999) 999-9999"
                      maskChar="_"
                      alwaysShowMask={true}
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="w-96 px-4 focus:ring-neatcar focus:border-neatcar shadow-sm border block sm:text-sm rounded-md"
                    />
                    <NeatcarButton type={'submit'} loading={loading} title={t('sendToPhone')} />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </form>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default DownloadAppModal;
