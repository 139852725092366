import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import NeatcarButton from './NeatcarButton';
import { useTranslation } from 'react-i18next';
import brokenFlag from '../assets/images/brokenFlag.png';
import closedFlag from '../assets/images/closedFlag.png';
import fourToSix from '../assets/images/fourToSix.png';
import oneToThree from '../assets/images/oneToThree.png';
import openFlag from '../assets/images/openFlag.png';
import sevenOrMore from '../assets/images/sevenOrMore.png';
import Tabs from "./Tabs";
import { CarwashType, FlagType } from "../models/stores";
import Badge from "./Badge";
import dayjs from "dayjs";
import DownloadAppModal from "./DownloadAppModal";

const StoreDetail = ({store, open, onClose}) => {
  const [buyWashModalOpen, setBuyWashModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const tabs = [
    { key: 'activities', name: t('Activities') },
    { key: 'washes', name: t('Washes') },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const getImageForState = (type: FlagType) => {
    switch(type.description) {
      case 'WAITING_1TO3':
        return oneToThree;
      case 'WAITING_4TO6':
        return fourToSix;
      case 'WAITING_7ORMORE':
        return sevenOrMore;
      case 'CLOSED':
        return closedFlag;
      case 'BROKEN':
        return brokenFlag;
      case 'OPEN':
      default:
        return openFlag;
    }
  }

  if (!store) {
    return <></>;
  }

  console.log(store);

  return (
    <>
      <DownloadAppModal buy={true} open={buyWashModalOpen} onClose={() => setBuyWashModalOpen(false)} />

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <div className="fixed inset-0 pointer-events-none" />

          <div className="fixed inset-0 overflow-hidden pointer-events-none">
            <div className="absolute inset-0 overflow-hidden pointer-events-none">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-hidden bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-end">
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 outline-none ring-none"
                              onClick={() => onClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {/* Replace with your content */}
                        <div className="absolute inset-0 px-4 sm:px-6">
                          <div className="h-full flex flex-col" aria-hidden="true">
                            <div className="flex flex-col items-center">
                              <img src={store.brand.smallLogo} width={64} alt={store.brand.name} />
                              <span className="text-2xl font-semibold mt-4">{store.brand.name}</span>
                              <span className="text-xl text-neatcarGray font-semibold leading-tight">{store.address}</span>
                              <div className="rounded-full text-xs font-semibold bg-neatcarBackground uppercase px-4 py-1 mt-4">{t('moreDetails')}</div>
                            </div>
                            <div className="flex-1 mt-8">
                              <Tabs tabs={tabs} currentTab={currentTab} selectedTab={currentTab} onSelect={tab => {
                                setCurrentTab(tab);
                              }} />
                              {currentTab.key === 'activities' && (
                                <div className="mt-8">
                                  {store.flags.map((flag: Flag, idx) =>
                                    <div key={flag.id}>
                                      <div className="flex flex-row items-center my-4 gap-x-2">
                                        <div className="">
                                          <img src={getImageForState(flag.type)} width={'48'} alt={i18n.language === 'fr' ? flag.type.nameFr : flag.type.nameEn} />
                                        </div>
                                        <div className="flex-1 flex flex-col">
                                          <div className="text-2xl font-bold leading-tight">{i18n.language === 'fr' ? flag.type.nameFr : flag.type.nameEn}</div>
                                          <div className="text-sm text-neatcarGray">{t('reportedBy')}<span className="text-neatcar ml-1">{flag.reporter}</span></div>
                                        </div>
                                        <div className="self-start text-neatcarGray text-xs font-semibold">
                                          {dayjs(flag.dateForTimezone).fromNow()}
                                        </div>
                                      </div>
                                      {idx !== store.flags.length - 1 && (<hr />)}
                                    </div>
                                  )}
                                </div>
                              )}
                              {currentTab.key === 'washes' && (
                                <div className="mt-8">
                                  <div className="flex flex-row items-center justify-end mb-4">
                                    <div className="text-success font-semibold text-xl w-24 text-center"><Badge>{t('PREMIUM')}</Badge></div>
                                    <div className="text-neatcar font-semibold text-xl w-24 text-center"><Badge>{t('STANDARD')}</Badge></div>
                                  </div>
                                  {store.carwashTypes.map((washType: CarwashType, idx) =>
                                    <div key={washType.id}>
                                      <div className="flex flex-row items-center my-4">
                                        <div className="flex-1 font-semibold text-xl">{i18n.language === 'fr' ? washType.nameFr : washType.nameEn}</div>
                                        <div className="text-neatcarGray font-semibold text-xs">30%</div>
                                        <div className="text-success font-semibold text-xl w-24 text-center">{t('price', {price: (washType.pricePro / 100).toFixed(2)})}</div>
                                        <div className="text-neatcar font-semibold text-xl w-24 text-center">{t('price', {price: (washType.price / 100).toFixed(2)})}</div>
                                      </div>
                                      {idx !== store.carwashTypes.length - 1 && (<hr />)}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            <div>
                              <hr className="mb-6"/>
                              <NeatcarButton title={t('buyAWash')} className={'text-xl py-6 rounded-full'} onClick={() => setBuyWashModalOpen(true)} />
                            </div>
                          </div>
                        </div>
                        {/* /End replace */}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default StoreDetail;
