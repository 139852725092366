import * as React from "react";

const NeatcarButton = (
  {
    disabled,
    type,
    title,
    onClick = () => {},
    inverse = false,
    gray = false,
    className,
    icon,
    loading,
  }) => {

  let style = ' border-transparent text-white bg-neatcar';

  if (inverse) {
    style = ' text-neatcar bg-white border-neatcar'
  }
  if (gray) {
    style = ' border-transparent text-neatcar bg-neatcarBackground'
  }

  if (disabled) {
    style += ' opacity-30 hover:opacity-30';
  }
  if (className) {
    style += ` ${className}`;
  }

  return (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={"w-full h-10 flex justify-center py-2 px-4 items-center border rounded-3xl shadow-sm text-sm font-medium hover:opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neatcar whitespace-nowrap" + style}
    >
      {icon && <img src={icon} width={'12'} className={'mr-2'} alt={title}/>}
      {title}
    </button>
  );
}

export default NeatcarButton;
