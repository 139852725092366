import Axios from 'axios-observable';
import dayjs from "dayjs";

const AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Env': process.env.REACT_APP_ENV,
    'X-App': 'map',
    Timezone: dayjs.tz.guess()
  },
  withCredentials: false
});

export function setupInterceptor () {
  AxiosInstance.interceptors.request.use(
    (config) => {
      return config;
    },
    error => {
      throw error;
    }
  );

  AxiosInstance.interceptors.response.use(
    response => {
      // Do something with response data
      return response;
    },
    error => {
      if (!error.response) {
        console.log(
          'HTTP call error',
          error
        );
        return Promise.reject(error);
      }
      // Do something with response error
      console.log(
        `HTTP call error [${error.response.status}] ${
          error.response.request.responseURL
        }`,
        error.response.data
      );
      throw error
    }
  );
}

setupInterceptor(AxiosInstance);

export default AxiosInstance;
