import React from 'react';

const Badge = (props) => {
  return (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-neatcarBackground text-neatcarGray">
        {props.children}
      </span>
  );
};

export default Badge;
