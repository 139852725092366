import React, { useEffect, useState } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

/*
 * const tabs = [
 *   { key: 'my', name: 'My Account' },
 *   { key: 'comp', name: 'Company' },
 * ]
 */
const Tabs = ({tabs, selectedTab, onSelect}: {tabs: [], currentTab: undefined, onSelect: () => void}) => {

  const [myCurrentTab, setMyCurrentTab] = useState(selectedTab);

  useEffect(() => {
    setMyCurrentTab(selectedTab)
  }, [selectedTab]);

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-neatcar focus:border-neatcar border-gray-300 rounded-md"
          defaultValue={myCurrentTab?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.key === myCurrentTab.key
                    ? 'border-neatcar text-neatcar'
                    : 'border-transparent text-neatcarGray hover:text-gray-700 hover:border-gray-300',
                  'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-xl cursor-pointer flex-1'
                )}
                aria-current={
                  tab.key === myCurrentTab.key ? 'page' : undefined}
                onClick={() => onSelect(tab)}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
};

export default Tabs;
