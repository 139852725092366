import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import fr from './locales/fr';
import dayjs from 'dayjs';

require('dayjs/locale/fr');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['common'],
    fallbackLng: 'en',
    lng: 'fr',
    resources: {
      en: en,
      fr: fr
    }
  }).resolve();

dayjs.locale(i18n.language);

export default i18n;
