import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BaseLayout from './components/layout/BaseLayout';
import NeatcarMap from './pages/NeatcarMap';
import './App.css';

const App = () => {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<BaseLayout/>}>
          <Route path={"/"} element={<NeatcarMap/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
