const fr = {
  common: {
    title: 'Neatcar LiveMap',
    buyAWash: 'Acheter un lavage',
    moreDetails: 'Plus de détail',
    Activities: 'Activités',
    Washes: 'Lavages',
    price: '{{price}}$',
    PREMIUM: 'PREMIUM',
    STANDARD: 'STANDARD',
    reportedBy: 'Reporté par',
    buyFromPhone: 'Achetez vos lavages depuis votre téléphone',
    sendToPhone: 'Envoyer sur mon téléphone',
    sendToPhoneDesc: 'Envoyez le lien de téléchargement sur votre téléphone',
    appStoreLink: 'https://apps.apple.com/ca/app/neatcar/id1499030487?l=fr',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.mecommerce.carwashremote&hl=fr_CA',
    scanTheCode: 'Scannez le code avec votre téléphone',
    scanTheCodeDesc: 'Utilisez l\'appareil photo de votre téléphone pour scanner et télécharger l\'application Neatcar.',
    phoneRequired: 'Le numéro de téléphone est requis',
    download: 'Télécharger',
    downloadDesc: 'Télécharger l\'application Neatcar sur votre téléphone depuis le',
    choose: 'Choisissez votre station',
    chooseDesc: 'Choisissez votre station parmis bos partenaires et sélectionnez le type de lavage souhaité',
    pay: 'Acheter',
    payDesc: 'Une fois votre type de lavage choisi, achetez le depuis l\'application et utiliser le code directement au lave-auto',
  }
};
export default fr;
