import AxiosInstance from './api';
import type { Store } from '../models/Stores';
import { map, Observable } from 'rxjs';

export function getStores (): Observable<Store[]> {
  return AxiosInstance.get(`/api/v1/store/available`)
    .pipe(
      map(res => res.data.data.stores)
    );
}
