import React, { useState } from 'react';
import icon from './../assets/images/icon.png';
import phone from './../assets/images/phone.png';
import downloadPlayStoreFr from './../assets/images/google-play-neatcar.png';
import downloadAppStoreFr from './../assets/images/app-store-neatcar.png';
import downloadPlayStoreEn from './../assets/images/google-play-neatcar-en.png';
import downloadAppStoreEn from './../assets/images/app-store-neatcar-en.png';
import { useTranslation } from "react-i18next";
import NeatcarButton from "./NeatcarButton";
import DownloadAppModal from "./DownloadAppModal";

export const BOTTOM_BANNER_HEIGHT = 60;

const DownloadBanner = () => {
  const [downloadAppModalOpen, setDownloadAppModalOpen] = useState(false);
  const { i18n, t } = useTranslation();

  return (
    <div className="static bottom-0 bg-white flex flex-1 items-center px-6 gap-x-4" style={{height: `${BOTTOM_BANNER_HEIGHT}px`}}>
      <DownloadAppModal open={downloadAppModalOpen} onClose={() => setDownloadAppModalOpen(false)}/>
      <div className="flex flex-row items-center gap-x-4 flex-1">
        <div>
          <img src={icon} alt={'Neatcar'} width={'36'} />
        </div>
        <div className="flex flex-col items-start">
          <div className="text-neatcar font-bold text-sm leading-none">Neatcar</div>
          <div className="text-neatcarGray font-bold text-xs leading-none">{t('buyFromPhone')}</div>
        </div>
      </div>
      <div>
        <NeatcarButton title={t('sendToPhone')} icon={phone} gray onClick={() => setDownloadAppModalOpen(true)}/>
      </div>
      <a href={t('playStoreLink')} target={'_blank'} rel="noreferrer">
        <img src={i18n.language === 'fr' ? downloadPlayStoreFr : downloadPlayStoreEn} width={'100'} alt={'Download on Google Play Store'} />
      </a>
      <a href={t('appStoreLink')} target={'_blank'} rel="noreferrer">
        <img src={i18n.language === 'fr' ? downloadAppStoreFr : downloadAppStoreEn} width={'100'} alt={'Download on Apple App Store'} />
      </a>
    </div>
  )
}

export default DownloadBanner;
