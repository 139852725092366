const en = {
  common: {
    title: 'Neatcar LiveMap',
    buyAWash: 'Buy a wash',
    moreDetails: 'More detail',
    Activities: 'Activities',
    Washes: 'Washes',
    // eslint-disable-next-line no-template-curly-in-string
    price: '${{price}}',
    PREMIUM: 'PREMIUM',
    STANDARD: 'STANDARD',
    reportedBy: 'Reported by',
    buyFromPhone: 'Buy your car washes from your phone',
    sendToPhone: 'Send to my phone',
    sendToPhoneDesc: 'Send the download link to your phone',
    appStoreLink: 'https://apps.apple.com/ca/app/neatcar/id1499030487',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.mecommerce.carwashremote&hl=en_CA',
    scanTheCode: 'Scan the code with your phone',
    scanTheCodeDesc: 'Use your phone\'s camera to scan and download the Neatcar app.',
    download: 'Download',
    downloadDesc: 'Download the Neatcar app on your phone from',
    choose: 'Choose your car wash',
    chooseDesc: 'Choose your car wash among our partners and select the wash type you like',
    pay: 'Buy',
    payDesc: 'Once your wash type chosen, buy it and use the code from your phone directly at the station',
  }
};

export default en;
